import apiClient from "../../axios/axios";
import store from "../../../store/index";

export async function getUnitTasks(stagesArray, flattenState) {
  try {
    console.log("STAGES ARRAY BEING SENT FOR TASKS: ", stagesArray);
    const endpoint = `/tasks_list`;
    const data = {
      proj_unit_id: store.state.unitModule.unit.id,
      frame_stage_id: stagesArray,
    };

    const res = await apiClient.post(endpoint, data);
    //console.log("RES FROM STORE", res.data.tasks.flat()); // <--- This is the data I need returned - ver com Isabela

    return flattenState
      ? { status: res.status, data: res.data.tasks.flat() } //.tasks shouldnt be here, look commented line above
      : { status: res.status, data: res.data.tasks };
  } catch (err) {
    console.log("Err on tasks store getter");
  }
}
